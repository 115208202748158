import React from 'react'
import { useIntl } from 'gatsby-plugin-react-intl'
import {
    Spacing,
} from '../../../kenra-storybook/index'
import HeroSlider from 'components/HeroSlider'
import Blocks from './RecentLaunchesBlocks'

export default function RecentLaunches(props) {
    let intl = useIntl()
    let {
        banners,
    } = props

    console.log('banners', banners)
    if (!banners?.length > 0) {
        return null
    }
    return (
        <>
            <Spacing removeSpaceTop>
                <HeroSlider slides={[banners[0]]} />
            </Spacing>
            <Spacing removeSpaceTop>
                <Blocks items={banners} />
            </Spacing>
        </>
    )
}
