import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import SEO from 'components/seo';
import get from 'lodash.get';
import RecentLaunches from '../components/CustomLanding/RecentLaunches/RecentLaunches'

export default function RecentCampaignsPage(props) {
    let {
        data: {
            contentfulCustomLandingPage,
            contentfulPage,
        },
    } = props;
    console.log('page', contentfulCustomLandingPage)
    return (
        <Layout>
            <SEO
                description={get(
                    contentfulPage,
                    'seoListing.description.description'
                )}
                title={get(contentfulPage, 'seoListing.seoTitle')}
                slug={'recent-launches'}
            />
            <RecentLaunches banners={contentfulCustomLandingPage.banner} />
        </Layout>
    );
}

export const query = graphql`
    query($locale: String) {
        contentfulCustomLandingPage(
            node_locale: { eq: $locale }
            slug: { eq: "recent-launches" }
        ) {
            ...ContentfulCustomLandingPageFields
        }
    }
`;
